<template>
  <form
    ref="form"
    class="quick-search"
    @submit.prevent="submit">
    <label class="quick-search__label">
      Search

      <span class="quick-search__input-wrapper">
        <button
          type="submit"
          aria-label="Submit search query"
          class="search-icon">
          <a-icon
            icon="fa:regular:search"
            role="presentation" />
        </button>

        <input
          ref="search"
          v-model="value"
          :placeholder="placeholder"
          type="text"
          aria-label="Enter your search query and press return to submit"
          @keyup.enter="submit">
      </span>
    </label>
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent( {
  name: "QuickSearch",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    filterAlias: {
      type: String,
      default: "query",
    },
    url: {
      type: String,
      default: "/search",
    },
    placeholders: {
      type: Array,
      default: () => {},
    },
  },
  emits: ["update:modelValue", "search"],
  data () {
    return {
      value: "",
      placeholder: "Search...",
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler ( val ) {
        this.value = val;
      },
    },
    value ( val ) {
      this.$emit( "update:modelValue", val );
    },
  },
  methods: {
    submit () {
      if ( this.value && this.url !== window.location.pathname ) {
        const queryObject : Record<string, string> = {};
        queryObject[this.filterAlias] = this.value;

        const params = new URLSearchParams( queryObject );

        window.location.href = `${this.url}?${params.toString()}`;
      } else {
        this.$emit( "search", this.value );
      }
    },
  },
} );
</script>

<style lang="scss" scoped>
.quick-search {
  display: block;
  position: relative;
  font-weight: 500;
  font-size: 1.25rem;

  &__input-wrapper {
    position: relative;
    display: block;
  }

  input {
    margin-top: 5px;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid var( --color-secondary-500 );
  }
}

.search-icon {
  position: absolute;
  right: 20px;
  top: calc( 50% - 17px );
  font-size: 24px;
  color: #b2b2b2;
  cursor: pointer;
  appearance: none;
  background: none;
  border: 0;

  &:hover {
    color: #888;
  }
}
</style>
